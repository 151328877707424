import React from 'react';
import * as ReactGA from 'react-ga';
import queryString from 'query-string';
import Layout from '../components/Shared/Layout';
import GetStartedTopBanner from '../components/Contact/lets_get_started_top_banner';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class GetStartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeClass: undefined,
      iframeSrc: undefined,
    };
  }

  componentDidMount() {
    ReactGA.set({
      title: 'LetsGetStarted.Form',
    });
    ReactGA.pageview(this.props.location.pathname);

    const parsed = this.props.location.search ? queryString.parse(this.props.location.search) : '';
    const engagementModel = parsed ? parsed.EM : '';
    const iframeClass = `Lets-get-started-iframe${
      engagementModel == '' ? '' : `-EM-${engagementModel}`
    }`;
    const iframeSrc = `https://tintashphaseone.paperform.co?embed=1&inline=1${
      engagementModel == '' ? '' : `&5vcvs=${engagementModel}`
    }`;

    this.setState({
      iframeClass,
      iframeSrc,
    });
  }

  render() {
    const {iframeClass, iframeSrc} = this.state;
    return (
      <Layout
        footerProps={{showPortfolioSection: false, showContactSection: false}}
        headerPosition="fixed"
      >
        <GetStartedTopBanner />
        {iframeClass ? (
          <div className="container mb-5 Lets-get-started-iframe-container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <iframe
                  className={iframeClass}
                  frameBorder="0"
                  width="100%"
                  src={iframeSrc}
                  height="100%"
                  scrolling="no"
                />
              </div>
            </div>
          </div>
        ) : null}
      </Layout>
    );
  }
}
export default GetStartPage;
