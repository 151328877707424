import React from 'react';
import {Helmet} from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Let's Get Started | Tintash</title>
    </Helmet>
    <section className="main-signup-heading">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 py-3">
            <h1 className="latoBlack text-white heading">Let's Talk!</h1>
            <p className="latoRegular text-white description get-started-description">
              Please fill out the form below and our team will get in touch with you shortly!
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);
